import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";

export function ErrorMessage({
  status = "error",
  title,
  description,
  ...rest
}) {
  return (
    <Alert
      status={status}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      minH="200px"
      bg="fg"
      py={8}
      {...rest}
    >
      <AlertIcon boxSize="40px" mr={0} />
      <AlertTitle mt={4} mb={1} fontSize="lg">
        {title}
      </AlertTitle>
      <AlertDescription maxWidth="md">{description}</AlertDescription>
    </Alert>
  );
}
